import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useApplication } from "@ryerson/frontend.application";
import { Badge } from "@ryerson/frontend.notification";
import { Media } from "@ryerson/frontend.layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useStaticQuery, graphql } from "gatsby";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import { BlogBadgeColor, TranslateBlogCategory } from "@components/Blog/Blog";

export type MetalInsightsStaticContent = {
	title1: string;
	title2: string;
	titleSeparator: string;
	actionUrl: string;
	actionLabel: string | string[];
};

export type MetalInsightsAndResourcesProps = {
	staticContent?: MetalInsightsStaticContent;
};

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	margin: 0px;
`;

const BlogItem = styled.div`
	display: block;
	width: 100%;
	height: 180px;
`;

const BlogItemImage = styled.div`
	display: inline-block;
	width: 300px;
	margin-right: 100px;
	vertical-align: top;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const BlogTitle = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	position: relative;
	height: 180px;
	margin-right: 140px;
`;

const BlogTitleBadge = styled.div`
	display: block;
	width: 100%;
	left: 0;
	bottom: 0;
	height: 30px;
	position: absolute;
`;

const BlogBlurb = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	padding-top: 5px;
	box-sizing: border-box;
	height: 180px;
`;

const MobileBlogItem = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 40px;
	height: auto;
`;

const MobileBlogItemImage = styled.div`
	display: block;
	width: 100%;
	position: relative;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const MobileBlogTitleBadge = styled.div`
	display: block;
	width: 100%;
	top: 20px;
	left: 20px;
	height: 24px;
	width: calc(100% - 40px);
	position: absolute;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

export const defaultStaticContent: LanguageContent<MetalInsightsStaticContent> = {
	en: {
		title1: "Metal Insights",
		title2: "Resources",
		titleSeparator: "&",
		actionUrl: "/metal-resources/metal-market-intelligence",
		actionLabel: ["More Metal Insights", "& Resources"],
	},
	fr: {
		title1: "Ressources et connaissances",
		title2: "matière de métaux",
		titleSeparator: "en",
		actionUrl: "/metal-resources/metal-market-intelligence",
		actionLabel: ["Ressources et connaissances", "en matière de métaux"],
	},
	es: {
		title1: "Datos",
		title2: "recursos sobre metales",
		titleSeparator: "y",
		actionUrl: "/metal-resources/metal-market-intelligence",
		actionLabel: ["Datos y recursos", "sobre metales"],
	},
};

const SideScroll = keyframes`
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
`;

const Marquee = styled.div`
	display: block;
	width: 100%;
	height: 170px;
	overflow: hidden;
	position: relative;
`;

const MetalInsightsAndResources: React.FC<MetalInsightsAndResourcesProps> = ({
	staticContent = null,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	if (!staticContent) {
		staticContent = defaultStaticContent[language];
	}

	const queryData = useStaticQuery(graphql`
		query AllBlogsForTopThreeQuery {
			allContentfulBlog(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					slug
					category
					title
					heroImage {
						file {
							url
						}
					}
					contentful_id
					publishDate
					seoDescription
					node_locale
				}
			}
		}
	`);
	let allBlogs: any = [];
	queryData?.allContentfulBlog?.nodes?.forEach((node: any) => {
		if (node?.node_locale.split("-").shift() === language) {
			allBlogs.push(node);
		}
	});
	allBlogs.sort(function (a: any, b: any) {
		return new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf();
	});

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Spacer
					css={css`
						height: 80px;
					`}
				/>
				<Marquee
					css={css`
						background-color: ${theme.colors.primary.background};
					`}
				>
					<Typography
						variant="h1"
						css={css`
							font-size: 150px;
							letter-spacing: -6px;
							position: absolute;
							width: 100%;
							height: 100%;
							margin: 0;
							line-height: 150px !important;
							white-space: nowrap;
							text-align: center;
							-moz-transform: translateX(100%);
							-webkit-transform: translateX(100%);
							transform: translateX(100%);
							-moz-animation: ${SideScroll} 8s linear infinite;
							-webkit-animation: ${SideScroll} 8s linear infinite;
							animation: ${SideScroll} 8s linear infinite;
						`}
					>
						<Typography
							variant="span"
							font="inherit"
							color={theme.colors.primary.header}
							weight="bold"
						>
							{staticContent.title1}{" "}
						</Typography>
						<Typography
							variant="span"
							font="inherit"
							color={theme.colors.primary.text}
							weight="bold"
						>
							{staticContent.titleSeparator}{" "}
						</Typography>
						<Typography
							variant="span"
							font="inherit"
							color={theme.colors.primary.header}
							weight="bold"
						>
							{staticContent.title2}
						</Typography>
					</Typography>
				</Marquee>
				<ContentSection type="primary" vPadding="80px">
					{allBlogs.slice(0, 3).map((blog: any, index: number) => {
						const heroUrl = blog?.heroImage?.file?.url
							? blog?.heroImage?.file?.url + "?fm=jpg&fit=fill&w=680&h=180"
							: "";
						return (
							<React.Fragment key={index}>
								<BlogItem>
									<BlogItemImage
										css={css`
											background-image: url(${heroUrl});
										`}
									/>
									<BlogTitle>
										<Link
											to={
												"/metal-resources/metal-market-intelligence/" +
												blog?.slug
											}
										>
											<Typography
												variant="h3"
												type="primary"
												css={css`
													margin: 0px;
												`}
											>
												{blog?.title}
											</Typography>
										</Link>
										<BlogTitleBadge>
											<Badge
												type="primary"
												color={BlogBadgeColor(
													TranslateBlogCategory(blog?.category)
												)}
											>
												{blog?.category}
											</Badge>
										</BlogTitleBadge>
									</BlogTitle>
									<BlogBlurb>
										<Typography variant="div" size="md" type="primary">
											{blog?.seoDescription}
										</Typography>
									</BlogBlurb>
								</BlogItem>
								<Divider
									css={css`
										border-bottom: 1px solid ${theme.colors.primary.darkerGray};
										opacity: 0.1;
										margin-top: 40px;
										margin-bottom: 39px;
									`}
								/>
							</React.Fragment>
						);
					})}
					<Typography
						variant="div"
						css={css`
							display: block;
							width: 100%;
							margin-top: 60px;
							margin-bottom: 60px;
						`}
					>
						<ButtonLink
							label={staticContent.actionLabel}
							to={staticContent.actionUrl}
							orientation="left"
							size="md"
						/>
					</Typography>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="35px" hPadding="0px">
					{allBlogs.slice(0, 3).map((blog: any, index: number) => {
						const heroUrl = blog?.heroImage?.file?.url
							? blog?.heroImage?.file?.url + "?fm=jpg&fit=fill&w=680&h=180"
							: "";
						return (
							<Link
								to={"/metal-resources/metal-market-intelligence/" + blog?.slug}
								key={index}
							>
								<MobileBlogItem>
									<MobileBlogItemImage
										css={css`
											background-image: url(${heroUrl});
										`}
									>
										<MobileBlogTitleBadge>
											<Badge
												type="tertiary"
												size="sm"
												color={BlogBadgeColor(
													TranslateBlogCategory(blog?.category)
												)}
											>
												{blog.category}
											</Badge>
										</MobileBlogTitleBadge>
									</MobileBlogItemImage>
									<Typography
										variant="h3"
										type="primary"
										css={css`
											margin-top: 25px;
											margin-bottom: 16px;
										`}
									>
										{blog?.title}
									</Typography>
									<Typography variant="div" size="md" type="primary">
										{blog?.seoDescription}
									</Typography>
								</MobileBlogItem>
							</Link>
						);
					})}
					<Divider
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.darkerGray};
							opacity: 0.1;
							margin-top: 35px;
							margin-bottom: 40px;
						`}
					/>
					<Typography
						variant="div"
						css={css`
							display: block;
							width: 100%;
							margin-bottom: 30px;
						`}
					>
						<ButtonLink
							label={staticContent.actionLabel}
							to={staticContent.actionUrl}
							orientation="left"
							size="md"
						/>
					</Typography>
				</ContentSection>
			</Media>
		</>
	);
};

export default MetalInsightsAndResources;
